strong {
  font-weight: bold;
}

.page-title {
  font-size: 45px;
  font-weight: 400;
  margin-top: 4px;
  font-style: normal;
  color: $text1;
  text-align: center;
}

.t {
  line-height: 1.4;
}

.t--bold {
  font-weight: bold;
}

.t--medium {
  font-weight: 500;
}

.t--10 {
  font-size: 10px;
}

.t--11 {
  font-size: 11px;
}

.t--12 {
  font-size: 12px;
}

.t--14 {
  font-size: 14px;
}

.t--16 {
  font-size: 16px;
}

.t--18 {
  font-size: 18px;
}

.t--white {
  color: $white;
}

.t--green {
  color: $text1;
}

.t--grey {
  color: $text3;
}

.t--uppercase {
  text-transform: uppercase;
}

.t--light {
  color: $text2;
}

.t--centered {
  text-align: center;
}

.t--file {
  text-decoration: underline;
  position: relative;
  display: block;
  margin-top: 16px;
  width: max-content;

  &::before {
    content: '';
    position: absolute;
    background: url("../../images/pdf.png") no-repeat center;
    width: 19px;
    top: -2px;
    height: 19px;
    right: -30px;
  }
}

.t--m-b {
  margin-bottom: 25px;
}

.t--32 {
  font-size: 32px;
}

.t--26 {
  font-size: 26px;
}

.t--22 {
  font-size: 26px;
}

.t--paragraph {
  margin-top: 60px;
}

.t--min-height {
  min-height: 115px;
}

a {
  color: $text1;
  text-decoration: none;
}
